exports.onClientEntry = () =>
  new Promise((resolve, reject) => {
    // eslint-disable-next-line no-underscore-dangle
    window.__polyfillio__ = () => {
      resolve();
    };

    if (
      'IntersectionObserver' in window &&
      'IntersectionObserverEntry' in window &&
      'intersectionRatio' in window.IntersectionObserverEntry.prototype
    ) {
      resolve();
      return;
    }

    const s = document.createElement('script');
    s.src =
      'https://cdn.polyfill.io/v2/polyfill.js?features=IntersectionObserver&callback=__polyfillio__';
    s.async = true;
    s.onerror = reject;
    document.head.appendChild(s);
  });

exports.onServiceWorkerUpdateFound = () => {
  window.location.reload();
};
