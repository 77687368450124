import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';
import { ThemeProvider } from 'styled-components';
import '../styles/globalStyles.css';
import theme from '../styles/theme';

if (typeof window !== 'undefined') {
  // eslint-disable-next-line global-require
  require('smooth-scroll')('a[href*="#"]', {
    speedAsDuration: true,
  });
}

const description =
  'Divine is an agency launched early 2017 dedicated to artists from the music industry, based on management, booking and event.';

const title = 'Divine Musique - Management, Booking & Event';

const Layout = ({ children }) => (
  <ThemeProvider theme={theme}>
    <div>
      <Helmet
        defaultTitle="Divine Musique - Management, Booking & Event"
        htmlAttributes={{
          lang: 'en',
        }}
        meta={[
          {
            content: description,
            name: `description`,
          },
          {
            content: title,
            property: `og:title`,
          },
          {
            content: description,
            property: `og:description`,
          },
          {
            content: `website`,
            property: `og:type`,
          },
          {
            content: 'https://www.divine-musique.com/images/banner.jpg',
            property: `og:image`,
          },
          {
            content: `summary_large_image`,
            name: `twitter:card`,
          },
          {
            content: '@divinemusique_',
            name: `twitter:creator`,
          },
          {
            content: title,
            name: `twitter:title`,
          },
          {
            content: description,
            name: `twitter:description`,
          },
          {
            content: 'https://www.divine-musique.com/images/banner.jpg',
            name: `twitter:image`,
          },
          {
            content: 'DIVINE',
            name: 'twitter:image:alt',
          },
          {
            content: '5GaKM-V-c934qj897Z_iCZgKoP7vLMYnTn7-kCva77Q',
            name: 'google-site-verification',
          },
        ]}
        titleTemplate="Divine Musique — %s"
      />
      <script type="application/ld+json">
        {`{
          "@context": "https://schema.org",
          "@type": "Corporation",
          "@id": "https://www.divine-musique.com",
          "legalName": "Divine SAS",
          "description": "Divine is an agency launched early 2017 dedicated to artists from the music industry, based on management, booking and event.",
          "url": "https://www.divine-musique.com",
          "address": {
            "@type": "PostalAddress",
            "addressLocality": "Paris",
            "addressCountry": "FR",
            "postalCode": "75116",
            "streetAddress": "54 rue Pergolèse"
          },
        "logo": "https://www.divine-musique.com/images/logo.png",
          "sameAs": [
            "https://twitter.com/divinemusique_",
            "https://www.instagram.com/divine.musique",
            "https://www.facebook.com/divinemusique/"
          ]
    }`}
      </script>
      {children}
    </div>
  </ThemeProvider>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
